<template>
  <div class="align-items-center">
    <button
      class="btn btn-secondary btn-sm"
      :class="lock ? ' bg-success text-success text-white' : ''"
      v-on:click="showModal()"
    >
      <BaseIcon name="edit-sqare" />
    </button>
    <span
      class="badge badge-outline"
      v-for="material in value?.materials"
      :key="material.id"
    >
      {{ material.name }}
    </span>
  </div>
  <IngredientsModal
    ref="ingredientsModal"
    :materials="value?.materials"
    :type="value?.type"
    v-on:change="change"
    v-on:change-raw="changeRaw"
    :id="id"
    v-model:lock="lock"
    :category="row?.row_key?.replace('ingredients_', '')"
  />
</template>
<script>
import IngredientsModal from "@/components/modals/Ingredients";
import BaseIcon from "@/components/icons/BaseIcon";
export default {
  name: "Ingredients",
  components: { BaseIcon, IngredientsModal },
  props: {
    id: Number,
    row: Object,
    modelValue: Object,
    extraRow: Array,
  },
  data() {
    return {
      value: this.modelValue,
      lock: false,
    };
  },
  computed: {
    categoryId() {
      return this.row?.row_key?.replace("ingredients_", "");
    },
  },
  watch: {
    extraRow() {
      this.lock = this.extraRow;
    },
    modelValue() {
      this.value = this.modelValue;
      if (this.value?.materials?.length > 0) {
        this.lock = this.value?.materials[0].lock == 1;
      }
    },
  },
  mounted() {
    if (this.value?.materials?.length > 0) {
      this.lock = this.value?.materials[0].lock == 1;
    }
    if (this.extraRow !== undefined) {
      this.lock = this.extraRow;
    }
  },
  methods: {
    changeRaw(data) {
      this.$emit("update", data, false);
    },
    showModal() {
      this.$refs.ingredientsModal.showModal();
    },
    change(materials) {
      console.log(materials);
      if (this.value === undefined || this.value === null) {
        this.value = {};
      }
      this.value.materials = materials;
      this.$emit("update:modelValue", this.value);
    },
  },
  emits: ["update:modelValue", "update"],
};
</script>
